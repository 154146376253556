import React from "react"
import { Helmet } from "react-helmet"
import "../styles/site.css"

export default () => (
   <div>
      <Helmet>
         <title>Pikseladam - An indie blog</title>
         <meta name="title" content="Pikseladam - An indie blog" />
         <meta name="description" content="Pikseladam bir indie blog sitesidir. Pikseladam sitesini; makalelerimi, notlarımı, ve internette bulduğum güzel şeyleri paylaşmak için kullanıyorum." />

         <meta property="og:type" content="website" />
         <meta property="og:site_name" content="Pikseladam" />
         <meta property="og:url" content="https://pikseladam.com/" />
         <meta property="og:title" content="Pikseladam - An indie blog" />
         <meta property="og:description" content="Pikseladam bir indie blog sitesidir. Pikseladam sitesini; makalelerimi, notlarımı, ve internette bulduğum güzel şeyleri paylaşmak için kullanıyorum." />

         <meta name="twitter:card" content="summary" />
         <meta name="twitter:url" content="https://pikseladam.com/" />
         <meta name="twitter:creator" content="@tc_gumus" />

         <link rel="micropub" href="https://pikseladam.com/micropub" />
         <link rel="microsub" href="https://aperture.p3k.io/microsub/493" />
         <link rel="authorization_endpoint" href="https://indieauth.com/auth" />
         <link rel="token_endpoint" href="https://tokens.indieauth.com/token" />
         <link rel="canonical" href="https://www.pikseladam.com/" />
         <link rel="webmention" href="https://webmention.io/pikseladam.com/webmention" />
         <link rel="pingback" href="https://webmention.io/pikseladam.com/xmlrpc" />
         <link rel="self" href="https://www.pikseladam.com/" />
         <link rel="alternate" type="application/rss+xml" title="RSS" href="https://www.pikseladam.com/rss.xml" />
      </Helmet>
      <p className="h-card hidden">
         <a className="p-name u-url" rel="me" href="https://pikseladam.com/">Pikseladam</a>
         <img className="u-photo" src="https://pikseladam.com/avatar.jpg" alt="pikseladam logo" />
         <span className="p-note">I'm Lead PLM Systems & Software Engineer. I code stuff & design things. #Arts for art. Working on http://turrsu.com and https://pikseladam.com for fun. #webmentions on pikseladam.</span>
      </p>
   </div>
)