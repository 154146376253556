import React from "react"
import "../styles/site.css"
import Head from "../shared/head"

export default ({ children }) => (
  <div id="wrapper">
    <Head />
    <div className="mx-auto container" >
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/12"></div>
        <div className="w-full lg:w-10/12">
          {children}
        </div>
        <div className="w-full lg:w-1/12"></div>
      </div>
    </div>
  </div>
)
