import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

export default () => (
<Layout>
   <div className="mx-auto text-xl">
      <div className="font-bold text-4xl text-gray-800 clearfix mt-6 px-4">
         <Link to="/" >pikseladam</Link>
         <span className=" text-xl text-gray-500">.com</span>
      </div>
      <div className=" text-blue-500 text-lg px-4">
         <div className="w-full">
            <img className="u-photo hidden" src="https://pikseladam.com/avatar.jpg" alt="pikseladam logo"/>
            <p className="p-note hidden">I'm Lead PLM Systems & Software Engineer. I code stuff & design things. #Arts for art. Working on http://turrsu.com and https://pikseladam.com for fun. #webmentions on pikseladam.</p>
            <a href="https://github.com/tcgumus/" className="hover:underline mr-3" rel="me">github</a>
            <a href="https://micro.blog/pikseladam" rel="me" className="hover:underline mr-3">micro.blog</a>
            <a href="https://www.instagram.com/tcgumus/" rel="me" className="hidden">instagram</a>
            <a href="https://dev.to/tcgumus" className="hover:underline mr-3" rel="me">dev.to</a>
            <a href="https://twitter.com/tc_gumus" className="hover:underline mr-3" rel="me"> twitter</a> 
         </div>
      </div>
      <div className="w-3/4">
      <div className="bg-yellow-300 text-base font-bold p-4 mt-8 text-black">Gizlilik Politikası</div>
      <div className="p-4 text-base">
      İKİZER MOBİL UYGULAMA GİZLİLİK BİLDİRİMİ
        Sürüm, 09 Kasım 2020
        <p>
Tüm Kullanıcılarımızın gizliliği bizim için çok önemlidir. Bir Uygulama Kullanıcısı olarak Hizmetimizi kullandığınızda, (Uygulama Yayıncısı olarak) Kişisel Verilerinizi işleriz. Bu Gizlilik Bildirimi, Kişisel Verilerinizi nasıl koruduğumuzu ve işleme koyduğumuzu açıklar. Dikkatlice okumanızı öneririz.</p>

<p>1. Bu Gizlilik Bildiriminde ne belirtilmiştir?</p>
 
<p></p>Bu Gizlilik Bildirimindeki bölümler size aşağıdakiler hakkında bilgi verir: 

<p></p>İşlenen Kişisel Verilerin türleri,
<p></p>Kişisel Verilerin işlenme amaçları, 
<p></p>Sorunuz veya açıklamalarınız varsa ne yapacaksınız? 

<p></p>2. İşlenen Kişisel Verilerin türleri ve kullanımı

<p></p>Hiç bir amaçla kişisel veriler işlenmemektedir ve toplanmamaktadır.

<p></p>3. Çocuk gizliliği

<p></p>Çocuklarla ilgili kişisel bilgiler bilerek veya kasıtlı olarak toplanmamaktadır.

<p></p>4. Herhangi bir sorunuz ya da açıklamalarınız varsa ne yapacaksınız?
<p></p>Bu Gizlilik Bildirimi ile ilgili herhangi bir sorunuz veya açıklamalarınız varsa, lütfen bir e-posta göndererek bizimle iletişime geçin. 

<p></p>Bu Gizlilik Bildirimi son olarak 09 Kasım 2020 tarihinde güncellendi.


      </div>
      </div> 
   </div>
</Layout>
)
